<template lang="html">
  <div id="app">
    <router-view />
  </div>
</template>

<script>
//import store from "./store";
export default {
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        console.log("test data", err.status);
        if (err.status === 401) {
          this.$store.dispatch("isLoggedIn");
        }
        throw err;
      });
    });
    // before any API call make sure that the access token is good
    // this.$http.interceptors.response.use(
    //   function (response) {
    //     return response;
    //   },
    //   async function (error) {
    //     console.log("err", error);
    //     if (error.response.status === 401) {
    //       return await store.dispatch("isLoggedIn");
    //     }
    //     return Promise.reject(error);
    //   }
    // );
    // before any API call make sure that the access token is good
    // this.$http.interceptors.response.use(undefined, async (error) => {
    //   /* refresh token and retry request once more on 401
    //      else log user out
    //   */
    //   const { config: originalReq, response } = error;
    //   console.log("originalReq", originalReq);
    //   if (
    //     originalReq.url !== "/auth/refresh-token" &&
    //     response.status === 401 &&
    //     !originalReq.isRetryAttempt &&
    //     response
    //   ) {
    //     await store.dispatch("isLoggedIn");
    //     originalReq.isRetryAttempt = true;
    //     return this.$http.interceptors.request(originalReq);
    //   }
    // });
  },
};
</script>

<style>
@import "../node_modules/vue-good-table/dist/vue-good-table.css";
@import "../node_modules/mdi/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/typicons.font/src/font/typicons.css";
</style>

<style lang="scss">
@import "assets/scss/shared/style";
// .form-control{
//   height: 35px !important;
// }
</style>

